<template>
  <div class="Zendesk_root"></div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { zendeskAuthHandler } from './_helpers/zendeskAuthHandler';
export default {
  computed: {
    ...mapState('user', ['_userModuleLoaded']),
  },
  methods: {
    ...mapActions('user', ['apiFetchUserData']),
    zendeskAuthRedirectHandler() {
      this.$router.push({ name: 'login', query: { zendesk_auth: 'true' } });
    },
  },
  mounted() {
    localStorage.getItem('kauri_finance_token') ? this.apiFetchUserData() : this.zendeskAuthRedirectHandler();
  },
  watch: {
    _userModuleLoaded: {
      handler(newVal) {
        if (newVal && this.$route.query.brand_id) zendeskAuthHandler();
      },
      immediate: true,
    },
  },
};
</script>
