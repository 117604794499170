import store from '@/store';

export const zendeskAuthHandler = () => {
  const jwt = require('jsonwebtoken');
  const privateKey = 'GudJr8GapSEe1FVxxt2RcpqQ3BEcsOuRMm0sQri6zcui8TMc';
  const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  var jwtToken = jwt.sign(
    {
      email: store?.state?.user?.userInfo?.email,
      name: store?.state?.user?.userInfo?.name || store?.state?.user?.userInfo?.email.split('@')[0],
      iat: Math.floor(Date.now() / 1000),
      jti: id,
    },
    privateKey,
  );
  window.location.href = `https://kaurifinance.zendesk.com/access/jwt?jwt=${jwtToken}`;
};

export default zendeskAuthHandler;
